/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.multiselect{
  li{
    color: black !important;
  }
}

.tooltip-inner {
  color: white !important;
  min-width: 70px !important;
  font-weight: 700 !important;
  padding: 2px 5px !important;
  background-color: rgba(65, 65, 65, 0.668) !important;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.tooltip {
  &.bs-tooltip-right .arrow:before {
    border-right-color: rgba(65, 65, 65, 0.668) !important;
  }

  &.bs-tooltip-top .arrow:before {
    border-top-color: rgba(65, 65, 65, 0.668) !important;
  }

  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: rgba(65, 65, 65, 0.668) !important;
  }

  &.bs-tooltip-left .arrow:before {
    border-left-color: rgba(65, 65, 65, 0.668) !important;
  }
}